module.exports = {
    albumArt: [
            {
                id: 1,
                name: "rulersAlbumArt",
                src: require('../images/rulersAlbumArt.png'),
                tag: "album_art_rulers_clicked"
            },
        ],
    background: {
        image: [
            {
                id: 1,
                name: "rulersBackgroundImage",
                file: require('../images/rulersAlbumArt.png'),
                tag: "background_art_rulers_clicked"
            },
        ]
    },
    video: {
        url:[
            {
                id: 1,
                url: "https://www.youtube.com/watch?v=65eeMKjnUFI",
                tag: "youtube_rulers_clicked"
            }
        ],
    },
    music: {
        links: {
            streaming: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/0dcKDhLWtPT8SmvwMTkmh4?si=2fd010bdfb324511",
                    albumName: {
                        rulers: "rulers"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/rulers/1635993625?i=1635993626",
                    albumName: {
                        rulers: "rulers"
                    },
                    tag: "apple_music_listen"
                },
                
            ],
            social: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/5XiqeLXfngt20RB2D2Tb1j?si=4c01bf3c637f4305",
                    albumName: {
                        rulers: "rulers"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/rulers/1635993625?i=1635993626",
                    albumName: {
                        rulers: "rulers"
                    },
                    tag: "apple_music_listen"
                },
                {
                    id: 3,
                    name: "iTunes",
                    iconImage: require('../../../assets/images/social/iconmonstr-itunes-5.svg'),
                    url: "https://music.apple.com/us/album/rulers/1635993625?i=1635993626",
                    albumName: {
                        rulers: "rulers"
                    },                   
                    tag: "itunes_listen"
                },
                {
                    id: 4,
                    name: "Tidal",
                    iconImage: require('../../../assets/images/social/iconmonstr-tidal-5.svg'),
                    url: "https://tidal.com/browse/track/239192474",
                    albumName: {
                        rulers: "rulers"
                    },                    
                    tag: "tidal_listen"
                },
                {
                    id: 5,
                    name: "Deezer",
                    iconImage: require('../../../assets/images/social/iconmonstr-deezer-5.svg'),
                    url: "https://deezer.page.link/5iFRwDHdiFWUKLHs7",
                    albumName: {
                        rulers: "rulers"
                    },                   
                    tag: "deezer_listen"
                },
                {
                    id: 6,
                    name: "Amazon Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-amazon-5.svg'),
                    url: "https://music.amazon.com/albums/B0B7C9PS77?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_rPSyO1GjrNntgNZ1SPbysp44R&trackAsin=B0B7CFN6Q4",
                    albumName: {
                        rulers: "rulers"
                    }, 
                    tag: "amazon_listen"
                },
                {
                    id: 7,
                    name: "Shazam",
                    iconImage: require('../../../assets/images/social/iconmonstr-shazam-5.png'),
                    url: "https://www.shazam.com/track/624763092/rulers",
                    albumName: {
                        rulers: "rulers"
                    },
                    tag: "shazam_listen"
                },
            ] 
        },
    }
}

