module.exports = {
    icons: [
        {
            id: 1,
            name: "PayPal",
            iconImage: require('../images/payment/iconmonstr-paypal-6.png'),
            url: "https://www.paypal.com/cgi-bin/webscr?cmd=_s-xclick&hosted_button_id=PPS88RUBBJSG2&source=url",
            tag: "paypal_donate"
        },
        {
            id: 2,
            name: "CashApp",
            iconImage: require('../images/payment/iconmonstr-cash-app-5.svg'),
            url: "https://cash.app/$KhriiistosArcana",
            tag: "cashapp_donate"
        },
        {
            id: 3,
            name: "Venmo",
            iconImage: require('../images/payment/iconmonstr-venmo-5.svg'),
            url: "https://venmo.com/code?user_id=2299361467826176261",
            tag: "venmo_donate"
        },
    ]
}