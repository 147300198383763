import React from "react";
import StreamingView from "../components/views/streamingViewComponent";
import release from '../releases/never/data/neverRelease';


const NeverStreaming = () => {
    return (
        <div>
            <StreamingView release={release} backgroundColor={'#1b2e2d'} />
        </div>
    );
};
export default NeverStreaming;