module.exports = {
    albumArt: [
            {
                id: 1,
                name: "neverAlbumArt",
                src: require('../images/neverAlbumArt.png'),
                tag: "album_art_never_clicked"
            },
        ],
    background: {
        image: [
            {
                id: 1,
                name: "neverBackgroundImage",
                file: require('../images/neverAlbumArt.png'),
                tag: "background_art_never_clicked"
            },
        ]
    },
    video: {
        url:[
            {
                id: 1,
                url: "https://www.youtube.com/watch?v=b0a48cIK_ws",
                tag: "youtube_never_clicked"
            }
        ],
    },
    music: {
        links: {
            streaming: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/2pilCozjTVzF2cNTjOY1MG?si=7tPRx494SCObNbthcJ_1rA&context=spotify%3Aalbum%3A7pzZE30sjjmIKk0Q7h0m1l",
                    albumName: {
                        never: "never"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/never/1745661974?i=1745661975",
                    albumName: {
                        never: "never"
                    },
                    tag: "apple_music_listen"
                },
                
            ],
            social: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/2pilCozjTVzF2cNTjOY1MG?si=7tPRx494SCObNbthcJ_1rA&context=spotify%3Aalbum%3A7pzZE30sjjmIKk0Q7h0m1l",
                    albumName: {
                        never: "never"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/never/1745661974?i=1745661975",
                    albumName: {
                        never: "never"
                    },
                    tag: "apple_music_listen"
                },
                {
                    id: 3,
                    name: "Youtube Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-youtube-music-5.svg'),
                    url: "https://music.youtube.com/watch?v=cdsKLw9o0Ec&si=hylTyiXZr_tjKIX0",
                    albumName: {
                        pendulum: "pendulum"
                    },                   
                    tag: "youtube_music_listen"
                },
                {
                    id: 4,
                    name: "Deezer",
                    iconImage: require('../../../assets/images/social/iconmonstr-deezer-5.svg'),
                    url: "https://www.deezer.com/us/album/585324902?deferredFl=1",
                    albumName: {
                        never: "never"
                    },                   
                    tag: "deezer_listen"
                },
                {
                    id: 5,
                    name: "Amazon Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-amazon-5.svg'),
                    url: "https://music.amazon.com/tracks/B0D3JKX2D8",
                    albumName: {
                        never: "never"
                    }, 
                    tag: "amazon_listen"
                },
                {
                    id: 6,
                    name: "Shazam",
                    iconImage: require('../../../assets/images/social/iconmonstr-shazam-5.png'),
                    url: "https://www.shazam.com/song/1745661975/never?referrer=share",
                    albumName: {
                        never: "never"
                    },
                    tag: "shazam_listen"
                },
            ] 
        },
    }
}

