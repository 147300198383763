const karcanaData = {
    albumArt: [
        {
            id: 1,
            name: "karcanaProjectAlbumArt",
            src: require("../images/karcanaAlbumArt.png"),
            tag: "album_art_karcanaProject_clicked"
        },
    ],
    background: {
        image: [
            {
                id: 1,
                name: "karcanaProjectBackgroundImage",
                src: require("../images/karcanaAlbumArt.png"),
                tag: "background_art_karcanaProject_clicked"
            },
        ]
    },
    video: {
        url: [
            {
            id: 1,
            url: "https://www.youtube.com/playlist?list=PLnmRuTuy5ZkIHrhxAqRiJxRgHSkAcXymQ",
            tag: "youtube_karcana_clicked"
            }
        ],
    },
    music: {
        links: {
            streaming: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/2UU0doQmhQSOzI9sSI6NoO?si=a5d26af1462b4edd",
                    albumName: {
                        karcanaProject: "karcanaProject"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://itunes.apple.com/us/artist/khriiistos-arcana/1353760536",
                    albumName: {
                        karcanaProject: "karcanaProject"
                    },
                    tag: "apple_music_listen"
                },
            ],
            social: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/2UU0doQmhQSOzI9sSI6NoO?si=a5d26af1462b4edd",
                    albumName: {
                        karcanaProject: "karcanaProject"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://itunes.apple.com/us/artist/khriiistos-arcana/1353760536",
                    albumName: {
                        karcanaProject: "karcanaProject"
                    },
                    tag: "apple_music_listen"
                },
                {
                    id: 3,
                    name: "iTunes",
                    iconImage: require('../../../assets/images/social/iconmonstr-itunes-5.svg'),
                    url: "https://itunes.apple.com/us/album/the-khriiistos-arcana-project/1454314202?uo=4&app=itunes&at=1001lry3&ct=dashboard",
                    albumName: {
                        karcanaProject: "karcanaProject"
                    },
                    tag: "itunes_listen"
                },
                {
                    id: 4,
                    name: "Tidal",
                    iconImage: require('../../../assets/images/social/iconmonstr-tidal-5.svg'),
                    url: "https://tidal.com/browse/artist/9603409",
                    albumName: {
                        karcanaProject: "karcanaProject"
                    },
                    tag: "tidal_listen"
                },
                {
                    id: 5,
                    name: "Deezer",
                    iconImage: require('../../../assets/images/social/iconmonstr-deezer-5.svg'),
                    url: "https://www.deezer.com/album/87971162",
                    albumName: {
                        karcanaProject: "karcanaProject"
                    },
                    tag: "deezer_listen"
                },
                {
                    id: 6,
                    name: "Amazon Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-amazon-5.svg'),
                    url: "http://www.amazon.com/gp/product/B07NXPM9ZX",
                    albumName: {
                        karcanaProject: "karcanaProject"
                    },
                    tag: "amazon_listen"
                },
                {
                    id: 7,
                    name: "Shazam",
                    iconImage: require('../../../assets/images/social/iconmonstr-shazam-5.png'),
                    url: "https://www.shazam.com/artist/204894005/khriiistos-arcana",
                    albumName: {
                        karcanaProject: "karcanaProject"
                    },
                    tag: "shazam_listen"
                },

            ]   
        }
    }

}

export default karcanaData;