import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";

import Error from './404';
import KAStreaming from './kaStreaming';
import KarcanaProjectSocial from './kaSocial';
import PurposeStreaming from './purposeStreaming';
import PurposeSocial from './purposeSocial';
import NeterStreaming from './neterStreaming';
import NeterSocial from './neterSocial';
import NeverStreaming from './neverStreaming';
import NeverSocial from './neverSocial';
import RulersStreaming from './rulersStreaming';
import RulersSocial from './rulersSocial';
import ConstellationsStreaming from './constellationsStreaming';
import ConstellationsSocial from './constellationsSocial';
import PendulumStreaming from './pendulumStreaming';
import PendulumSocial from './pendulumSocial';
import '../firebase';

require('dotenv').config()

const Webpages = () => {
    return(
        <Router>
            <Switch>
                <Route path = "/pendulum" component = {PendulumStreaming} />
                <Route path = "/pendulum-sm" component = {PendulumSocial} />
                <Route path = "/constellations" component = {ConstellationsStreaming} />
                <Route path = "/constellations-sm" component = {ConstellationsSocial} />
                <Route path = "/rulers" component = {RulersStreaming} />
                <Route path = "/rulers-sm" component = {RulersSocial} />
                <Route path = "/neter" component = {NeterStreaming} />
                <Route path = "/neter-sm" component = {NeterSocial} />
                <Route path = "/never" component = {NeverStreaming} />
                <Route path = "/never-sm" component = {NeverSocial} />
                <Route path = "/purpose" component = {PurposeStreaming} />
                <Route path = "/purpose-sm" component = {PurposeSocial} />
                <Route path = "/sm" component = {KarcanaProjectSocial} />
                <Route path = "/streaming" component = {KAStreaming} />
                <Redirect strict from="/" to="/sm" />
                <Route component={Error}/>
            </Switch>
        </Router>
    );
};
export default Webpages;