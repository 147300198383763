import React from "react";
import StreamingView from "../components/views/streamingViewComponent";
import release from '../releases/rulers/data/rulersRelease'

const RulersStreaming = () => {
    return (
        <div>
            <StreamingView release={release} backgroundColor={'#23071a'}/>
        </div>
    );
};
export default RulersStreaming;