import React from "react";
import SocialView from "../components/views/socialViewComponent.js";
import release from '../releases/pendulum/data/pendulumRelease.js';

const PendulumSocial = () => {
    return (
        <div>
            <SocialView release={release} backgroundColor={'#23071a'} />
        </div>
    );
};
export default PendulumSocial;
