module.exports = {
    albumArt: [
            {
                id: 1,
                name: "constellationsAlbumArt",
                src: require('../images/constellationsAlbumArt.jpg'),
                tag: "album_art_constellations_clicked"
            },
        ],
    background: {
        image: [
            {
                id: 1,
                name: "constellationsBackgroundImage",
                file: require('../images/constellationsAlbumArt.jpg'),
                tag: "background_art_constellations_clicked"
            },
        ]
    },
    video: {
        url:[
            {
                id: 1,
                url: "https://www.youtube.com/watch?v=QOphBDn9j3o",
                tag: "youtube_constellations_clicked"
            }
        ],
    },
    music: {
        links: {
            streaming: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/6HBR4qMKuJE8BrE7N4eNtB?si=X_t3eFVfTmm4hsN2pOyH2A",
                    albumName: {
                        constellations: "constellations"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/constellations/1699386439",
                    albumName: {
                        constellations: "constellations"
                    },
                    tag: "apple_music_listen"
                },
                
            ],
            social: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/3qi2MBy3AGXbMjq8ZyAVpZ?si=859e7bca534c4c79",
                    albumName: {
                        constellations: "constellations"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/constellations/1699386439",
                    albumName: {
                        constellations: "constellations"
                    },
                    tag: "apple_music_listen"
                },
                {
                    id: 3,
                    name: "Youtube Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-youtube-music-5.svg'),
                    url: "https://music.youtube.com/watch?v=V8M7FLp8Fjc&feature=share",
                    albumName: {
                        constellations: "constellations"
                    },                   
                    tag: "youtube_music_listen"
                },
                {
                    id: 4,
                    name: "Deezer",
                    iconImage: require('../../../assets/images/social/iconmonstr-deezer-5.svg'),
                    url: "https://www.deezer.com/us/album/469069565?deferredFl=1",
                    albumName: {
                        constellations: "constellations"
                    },                   
                    tag: "deezer_listen"
                },
                {
                    id: 5,
                    name: "Amazon Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-amazon-music-5.svg'),
                    url: "https://music.amazon.com/albums/B0CCVWZQXN?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_qagUMYnyR7O5ZoTFyQOaKsaND",
                    albumName: {
                        constellations: "constellations"
                    }, 
                    tag: "amazon_listen"
                },
                {
                    id: 6,
                    name: "Shazam",
                    iconImage: require('../../../assets/images/social/iconmonstr-shazam-5.png'),
                    url: "https://www.shazam.com/artist/khriiistos-arcana/1353760536",
                    albumName: {
                        constellations: "constellations"
                    },
                    tag: "shazam_listen"
                },
            ] 
        },
    }
}

