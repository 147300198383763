import React from "react";
import StreamingView from "../components/views/streamingViewComponent";
import release from '../releases/neter/data/neterRelease';

const NeterStreaming = () => {
    return (
        <div>
            <StreamingView release={release} backgroundColor="#32496b" />
        </div>
    );
};
export default NeterStreaming;