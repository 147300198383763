import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './firebase'
import * as serviceWorker from './serviceWorker';
import '../src/assets/css/index.css';
import '../src/assets/css/App.css';
import '../src/assets/css/albumArt.css';
import '../src/assets/css/buttonGroupIcons.css';
import '../src/assets/css/icons.css';
import '../src/assets/css/musicGrid.css';
import '../src/assets/css/player.css';
import '../src/assets/css/page.css';
import '../src/assets/css/pageFooter.css';
import 'bootstrap/dist/css/bootstrap.min.css';
require('dotenv').config()

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// https://www.golangprograms.com/how-to-create-simple-react-router-to-navigate-multiple-pages.html