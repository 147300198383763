import React from 'react';
import StreamingView from '../components/views/streamingViewComponent';
import release from '../releases/timefly/data/timeFlyRelease';


const KAStreaming = () => {
  return (
    <div>
      <StreamingView release={release} backgroundColor={'#2e2e2e'} />
    </div>
  );
};
export default KAStreaming;
