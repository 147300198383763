// analyticsWrapper.js

import firebase from '../firebase';
import 'firebase/auth';
import 'firebase/analytics';
import { snakeCase } from 'lodash';

let instance = null;

class AnalyticsWrapper {
    constructor() {
        if (!instance) {
            instance = this;
        }
        firebase
            .auth()
            .signInAnonymously()
            .then((credential) => {
                if (credential) {
                    console.log('default app user ->', credential.user.toJSON());
                }
            })
            .catch((error) => {
                console.log('Firebase Error: ', error);
            });
        return instance;
    }

    event(name, payload) {
        console.log('Event: ', name, payload);
        firebase.analytics().logEvent(snakeCase(name), payload);
    }
}

export const analytics = new AnalyticsWrapper();
