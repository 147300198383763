import React from "react";
import StreamComponent from '../Streaming'
import Video from "../Video";
import Social from '../Social';
import Header from "../header/Header";
import Donation from "../donation/Donation";
import Message from "../smsMessage/Message";
import Footer from "../footer/Footer";

// Data for Social & Donation Props
import donate from "../../assets/donation/donationIcons";
import social from "../../assets/social/socialMediaIcons";

/* Documentation */
// https://dmitripavlutin.com/react-props/
// https://medium.com/@nutanbhogendrasharma/create-multiple-pages-using-routing-in-react-part-2-5d667ca051c6
// https://www.pluralsight.com/guides/change-page-background-color-each-route

const SocialView = ({ release, backgroundColor }) => {
    return (
        <div>
            <div className="container-fluid">
                <div style={{ backgroundColor: backgroundColor }} className="pageBg" />

                {release.albumArt.map(art => (
                    <Header key={art.id} {...art} />
                ))}

                {release.video.url.map(video => (
                    <Video key={video.id} {...video} />
                ))}

                {release.music.links.social.map(music => (
                    <StreamComponent key={music.id} {...music} />
                ))}

                <div>
                    {social.icons.map((icon, index) => (
                        <Social key={index} {...icon} />
                    ))}
                </div>

                <div>
                    {donate.icons.map((icon, index) => (
                        <Donation key={index} {...icon} />
                    ))}
                </div>
            </div>
            <div className="footer">
                <Message tag={"sms_subscribe"} />
                <Footer />
            </div>
        </div>
    );
};

export default SocialView;
