import React, { Component } from "react";


class Icons extends Component {
  render() {
    return (
      <div className="button-container">
      <div className="btn-group" role="group">
          <img
            key={this.props.id}
            src={this.props.iconImage}
            label={this.props.name}
            alt={this.props.name}
            className="btn-img"
          />
        </div>
      </div>
    );
  }
}

export default Icons;


// with btn-group, this looks much better, 
// but amazon music goes out of bounds