module.exports = {
    albumArt: [
            {
                id: 1,
                name: "pendulumAlbumArt",
                src: require('../images/pendulumAlbumArt.png'),
                tag: "album_art_pendulum_clicked"
            },
        ],
    background: {
        image: [
            {
                id: 1,
                name: "pendulumBackgroundImage",
                file: require('../images/pendulumAlbumArt.png'),
                tag: "background_art_pendulum_clicked"
            },
        ]
    },
    video: {
        url:[
            {
                id: 1,
                url: "https://www.youtube.com/watch?v=Df0Q3oZMQAA",
                tag: "youtube_pendulum_clicked"
            }
        ],
    },
    music: {
        links: {
            streaming: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/6n6Px8GjIU6s5amE2fKUU4?si=24026ccecf3f490f",
                    albumName: {
                        pendulum: "pendulum"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/pendulum/1713479469",
                    albumName: {
                        pendulum: "pendulum"
                    },
                    tag: "apple_music_listen"
                },
                
            ],
            social: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/6n6Px8GjIU6s5amE2fKUU4?si=24026ccecf3f490f",
                    albumName: {
                        pendulum: "pendulum"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/pendulum/1713479469",
                    albumName: {
                        pendulum: "pendulum"
                    },
                    tag: "apple_music_listen"
                },
                {
                    id: 3,
                    name: "Youtube Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-youtube-music-5.svg'),
                    url: "https://music.youtube.com/watch?v=gwvuJ2fX_wI&feature=shared",
                    albumName: {
                        pendulum: "pendulum"
                    },                   
                    tag: "youtube_music_listen"
                },
                {
                    id: 4,
                    name: "Deezer",
                    iconImage: require('../../../assets/images/social/iconmonstr-deezer-5.svg'),
                    url: "https://deezer.page.link/vxVP5jndR4iqiAjv6",
                    albumName: {
                        pendulum: "pendulum"
                    },                   
                    tag: "deezer_listen"
                },
                {
                    id: 5,
                    name: "Amazon Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-amazon-music-5.svg'),
                    url: "https://music.amazon.com/albums/B0CLR2NKPJ?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_hRIdpfoi3xPca0oVowv4M96bG&trackAsin=B0CLQYPKNB",
                    albumName: {
                        pendulum: "pendulum"
                    }, 
                    tag: "amazon_listen"
                },
                {
                    id: 6,
                    name: "Shazam",
                    iconImage: require('../../../assets/images/social/iconmonstr-shazam-5.png'),
                    url: "https://www.shazam.com/artist/khriiistos-arcana/1353760536",
                    albumName: {
                        pendulum: "pendulum"
                    },
                    tag: "shazam_listen"
                },
            ] 
        },
    }
}

