import React from "react";
import release from '../releases/constellations/data/constellationsRelease';
import StreamingView from "../components/views/streamingViewComponent";

const ConstellationsStreaming = () => { 
    return (
        <div>
            <StreamingView release={release} backgroundColor={'#460e33'}/>
        </div>
    );
};
export default ConstellationsStreaming;