import React from "react";
import SocialView from "../components/views/socialViewComponent";
import release from '../releases/purpose/data/purposeRelease';


const PurposeSocial = () => {
    return (
        <div>
            <SocialView release={release} backgroundColor={'#23071a'} />
        </div>
    );
};
export default PurposeSocial;
