import React from 'react';
import SocialView from '../components/views/socialViewComponent';
import release from '../releases/karcana/data/karcanaRelease';

const KarcanaProjectSocial = () => {
    return (
        <div>
            <SocialView release={release} backgroundColor={'#2e2e2e'}/>
        </div>
    );
};
export default KarcanaProjectSocial;