import React, { Component } from "react";
import Mailchimp from "react-mailchimp-form";

const url =
  "https://khriiistosarcana.us5.list-manage.com/subscribe/post?u=3a1ecb38935faa20bc03b6b1d&amp;id=4d8d8fed05";

class Footer extends Component {
  render() {
    return (
      <div className="mail-form">
        <Mailchimp
          action={url}
          fields={[
            {
              name: "EMAIL",
              placeholder: "Enter your email address",
              type: "email",
              required: true
            }
          ]}
          messages = {
            {
              sending: "Sending...",
              success: "Thank you for subscribing!",
              error: "Please enter a valid email address.",
              empty: "You must write an e-mail.",
              duplicate: "Too many subscribe attempts for this email address",
              button: "Subscribe"
            }
          }
        />
      </div>
    );
  }
}

export default Footer;
