module.exports = {
    albumArt: [
            {
                id: 1,
                name: "neterAlbumArt",
                src: require('../images/neterAlbumArt.png'),
                tag: "album_art_neter_clicked"
            },
        ],
    background: {
        image: [
            {
                id: 1,
                name: "neterBackgroundImage",
                file: require('../images/neterAlbumArt.png'),
                tag: "background_art_neter_clicked"
            },
        ]
    },
    video: {
        url:[
            {
                id: 1,
                url: "https://www.youtube.com/watch?v=5g6aEcsrHoc",
                tag: "youtube_neter_clicked"
            }
        ],
    },
    music: {
        links: {
            streaming: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/19wlts1FxPanHao4lNpcPy?si=5b78a553ad924c6f",
                    albumName: {
                        neter: "neter"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/neter/1622193791?i=1622193792",
                    albumName: {
                        neter: "neter"
                    },
                    tag: "apple_music_listen"
                },
                
            ],
            social: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/19wlts1FxPanHao4lNpcPy?si=5b78a553ad924c6f",
                    albumName: {
                        neter: "neter"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/neter/1622193791?i=1622193792",
                    albumName: {
                        neter: "neter"
                    },
                    tag: "apple_music_listen"
                },
                {
                    id: 3,
                    name: "iTunes",
                    iconImage: require('../../../assets/images/social/iconmonstr-itunes-5.svg'),
                    url: "https://music.apple.com/us/album/neter/1622193791?i=1622193792",
                    albumName: {
                        neter: "purpose"
                    },                   
                    tag: "itunes_listen"
                },
                {
                    id: 4,
                    name: "Tidal",
                    iconImage: require('../../../assets/images/social/iconmonstr-tidal-5.svg'),
                    url: "https://tidal.com/browse/track/227306298",
                    albumName: {
                        neter: "neter"
                    },                    
                    tag: "tidal_listen"
                },
                {
                    id: 5,
                    name: "Deezer",
                    iconImage: require('../../../assets/images/social/iconmonstr-deezer-5.svg'),
                    url: "https://deezer.page.link/1roDdUKrXBU2GSWv9",
                    albumName: {
                        neter: "neter"
                    },                   
                    tag: "deezer_listen"
                },
                {
                    id: 6,
                    name: "Amazon Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-amazon-5.svg'),
                    url: "https://music.amazon.com/albums/B09ZF6RWPK?ref=dm_sh_7c1IqC2p6oYDdh2mJWWmuhu2R",
                    albumName: {
                        neter: "neter"
                    }, 
                    tag: "amazon_listen"
                },
                {
                    id: 7,
                    name: "Shazam",
                    iconImage: require('../../../assets/images/social/iconmonstr-shazam-5.png'),
                    url: "https://www.shazam.com/track/606040355/purpose?referrer=share",
                    albumName: {
                        neter: "neter"
                    },
                    tag: "shazam_listen"
                },
            ] 
        },
    }
}

