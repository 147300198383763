import React from "react";
import StreamingView from "../components/views/streamingViewComponent.js";
import release from '../releases/pendulum/data/pendulumRelease.js';

const PendulumStreaming = () => { 
    return (
        <div>
            <StreamingView release={release} backgroundColor={'#23071a'}/>
        </div>
    );
};
export default PendulumStreaming;