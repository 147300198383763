import React from "react";
import SocialView from "../components/views/socialViewComponent";
import release from '../releases/neter/data/neterRelease';

const NeterSocial = () => {
    return (
        <div>
            <SocialView release={release} backgroundColor='#32496b' />
        </div>
    );
};
export default NeterSocial;
