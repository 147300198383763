import React, { Component } from "react";
import {analytics} from "../utils/analyticsWrapper";
import image from '../assets/images/404-error.jpeg'

 
class Error extends Component {

    handleClick(name,payload) {
        console.log("clickEvent: "+name);
        analytics.event(name,payload);
      }

    render() {
        return (
            <div>
                <div className="album-art-container">
                 <img 
                 src={image} 
                 className="album-art" 
                 alt="album-art"
                 onClick={() => this.handleClick("error404", "") } />
                 </div>
            </div>
         );

    }
}
 
export default Error;