import React, { Component } from "react";
import { analytics } from "../../utils/analyticsWrapper";


class Donation extends Component {

  handleClick(name, payload) {
    console.log("clickEvent: " + name);
    analytics.event(name, payload);
  }

  render() {
    return (
      <div className="button-container" onClick={() => {
        this.handleClick(this.props.tag, "");
        window.location.href = this.props.url;
      }}>
        <div className="btn-group" role="group">
          <img src={this.props.iconImage}
            key={this.props.id}
            label={this.props.name}
            alt={this.props.name}
            className="btn-img"
          />
        </div>
      </div>
    );
  }
}

export default Donation;
