module.exports = {
    albumArt: [
            {
                id: 1,
                name: "purposeAlbumArt",
                src: require('../images/purposeAlbumArt.jpeg'),
                tag: "album_art_purpose_clicked"
            },
        ],
    background: {
        image: [
            {
                id: 1,
                name: "purposeBackgroundImage",
                file: require('../images/purposeAlbumArt.jpeg'),
                tag: "background_art_purpose_clicked"
            },
        ]
    },
    video: {
        url:[
            {
                id: 1,
                url: "https://youtu.be/-rpZ0yx7vko",
                tag: "youtube_purpose_clicked"
            }
        ],
    },
    music: {
        links: {
            streaming: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/4f5lr6SmwKx2vyvW1uZA1m?si=C1-K5L4LQNqFI6rgD2B8ww",
                    albumName: {
                        purpose: "purpose"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/purpose-single/1611699628",
                    albumName: {
                        purpose: "purpose"
                    },
                    tag: "apple_music_listen"
                },
                
            ],
            social: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/5Vvwje41tNfJxIAmddiyqi?si=619d27cf88ca46c4",
                    albumName: {
                        purpose: "purpose"
                    },
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/purpose-single/1611699628",
                    albumName: {
                        purpose: "purpose"
                    },
                    tag: "apple_music_listen"
                },
                {
                    id: 3,
                    name: "iTunes",
                    iconImage: require('../../../assets/images/social/iconmonstr-itunes-5.svg'),
                    url: "https://music.apple.com/us/album/purpose-single/1611699628",
                    albumName: {
                        purpose: "purpose"
                    },                   
                    tag: "itunes_listen"
                },
                {
                    id: 4,
                    name: "Tidal",
                    iconImage: require('../../../assets/images/social/iconmonstr-tidal-5.svg'),
                    url: "https://tidal.com/browse/album/218199022",
                    albumName: {
                        purpose: "purpose"
                    },                    
                    tag: "tidal_listen"
                },
                {
                    id: 5,
                    name: "Deezer",
                    iconImage: require('../../../assets/images/social/iconmonstr-deezer-5.svg'),
                    url: "https://deezer.page.link/B6A46zZ8ssimWuqB9",
                    albumName: {
                        purpose: "purpose"
                    },                   
                    tag: "deezer_listen"
                },
                {
                    id: 6,
                    name: "Amazon Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-amazon-5.svg'),
                    url: "https://amazon.com/music/player/albums/B09TD2WW1M?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_qPLHYFQfUAXQh9CMDcgffqKGy",
                    albumName: {
                        purpose: "purpose"
                    }, 
                    tag: "amazon_listen"
                },
                {
                    id: 7,
                    name: "Shazam",
                    iconImage: require('../../../assets/images/social/iconmonstr-shazam-5.png'),
                    url: "https://www.shazam.com/track/606040355/purpose",
                    albumName: {
                        purpose: "purpose"
                    },
                    tag: "shazam_listen"
                },
            ]
            
        },
    }
}

