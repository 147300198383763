import React from "react";
import SocialView from "../components/views/socialViewComponent";
import release from '../releases/rulers/data/rulersRelease';

const RulersSocial = () => {
    return (
        <div>
            <SocialView release={release} backgroundColor={'#23071a'} />
        </div>
    );
};

export default RulersSocial;
