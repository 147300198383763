import React from "react";
import SocialView from "../components/views/socialViewComponent";
import release from '../releases/constellations/data/constellationsRelease';


const ConstellationsSocial = () => {
    return (
        <div>
            <SocialView release={release} backgroundColor={'#460e33'} />
        </div>
    );
};
export default ConstellationsSocial;
