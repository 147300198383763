import React, { Component } from "react";
import { analytics } from "../utils/analyticsWrapper";
import ListGroup from "react-bootstrap/ListGroup";
import Icons from "../components/Icons";

class StreamComponent extends Component {
  handleClick(name, payload) {
    console.log("clickEvent: " + name);
    analytics.event(name, payload);
  }
  render() {
    return (
      <div className="music-grid-buttons" onClick={() => { 
        this.handleClick(this.props.tag, "");
        window.location.href = this.props.url;
      }}>
        <div>
          <ListGroup.Item
            key={this.props.id}
            label={this.props.name}
            variant="light"
            action
          >
            <Icons
              key={this.props.id}
              label={this.props.name}
              iconImage={this.props.iconImage}
              url={this.props.url}
            />
          </ListGroup.Item>
        </div>
      </div>
    );
  }
}

export default StreamComponent;