import React, { Component } from "react";
import { analytics } from "../../utils/analyticsWrapper.js";


class Header extends Component {
  
  handleClick(name,payload) {
    console.log("clickEvent: " +name);
    analytics.event(name,payload);
  }
  
  render() {
    return (
      <div className="album-art-container">
        <img
          id={this.props.id}
          name={this.props.name}
          src={this.props.src}
          className="album-art" alt="album-art"
          onClick={() => this.handleClick(this.props.tag, "")} />
      </div>
    )
  }
}

export default Header;