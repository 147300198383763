import React from "react";
import ReactPlayer from 'react-player'

class Video extends React.Component {
  render () {
      return (
        <div className='player-wrapper'>
          <ReactPlayer
            id={this.props.id}
            className='react-player'
            url={this.props.url}
            width='100%'
            height='100%'
            controls={true}
            onClick={() => this.handleClick(this.props.tag, "") }
          />
        </div>
      )
    }
}

export default Video