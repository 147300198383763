module.exports = {
    albumArt: [
            {
                id: 1,
                name: "timeFlyAlbumArt",
                src: require('../images/timeFlyAlbumArt.png'),
                tag: "album_art_timeFly_clicked"
            },
        ],
    background: {
        image: [
            {
                id: 1,
                name: "timeFlyBackgroundImage",
                src: require('../images/timeFlyAlbumArt.png'),
                tag: "background_art_timeFly_clicked"
            },
        ]
    },
    video: {
        youtube: "https://www.youtube.com/playlist?list=PLnmRuTuy5ZkIHrhxAqRiJxRgHSkAcXymQ",
        tag: "timeFly_youtube_clicked"
    },
    music: {
        links: {
            streaming: [
                {
                    id: 1,
                    name: "Spotify",
                    iconImage: require('../../../assets/images/social/iconmonstr-spotify-5.svg'),
                    url: "https://open.spotify.com/track/2UU0doQmhQSOzI9sSI6NoO?si=e8d0072e1cbb4e2d",
                    albumName: {
                        timeFly: "timeFly"
                    },
                    advertising: true,
                    social: true,
                    tag: "spotify_listen"
                },
                {
                    id: 2,
                    name: "Apple Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-apple-music-5.svg'),
                    url: "https://music.apple.com/us/album/time-fly/1622193791?i=1622193807",
                    albumName: {
                        timeFly: "timeFly"
                    },
                    advertising: true,
                    social: true,
                    tag: "apple_music_listen"
                },
            ],
            social: [
                {
                    id: 3,
                    name: "iTunes",
                    iconImage: require('../../../assets/images/social/iconmonstr-itunes-5.svg'),
                    url: "https://music.apple.com/us/album/time-fly/1622193791?i=1622193807",
                    albumName: {
                        timeFly: "timeFly"
                    },
                    advertising: false,
                    social: true,
                    tag: "itunes_listen"
                },
                {
                    id: 4,
                    name: "Tidal",
                    iconImage: require('../../../assets/images/social/iconmonstr-tidal-5.svg'),
                    url: "https://tidal.com/browse/track/227306311",
                    albumName: {
                        timeFly: "timeFly"
                    },
                    advertising: false,
                    social: true,
                    tag: "tidal_listen"
                },
                {
                    id: 5,
                    name: "Deezer",
                    iconImage: require('../../../assets/images/social/iconmonstr-deezer-5.svg'),
                    url: "https://deezer.page.link/rq6XtZbTaXuhDFoD8",
                    albumName: {
                        timeFly: "timeFly"
                    },
                    advertising: false,
                    social: true,
                    tag: "deezer_listen"
                },
                {
                    id: 6,
                    name: "Amazon Music",
                    iconImage: require('../../../assets/images/social/iconmonstr-amazon-5.svg'),
                    url: "https://music.amazon.com/albums/B09ZF6RWPK?marketplaceId=ATVPDKIKX0DER&musicTerritory=US&ref=dm_sh_E49Wolp4VXmUPHB6TzDQMLmH9&trackAsin=B09ZF7BTFD",
                    albumName: {
                        timeFly: "timeFly"
                    },
                    advertising: false,
                    social: true,
                    tag: "amazon_listen"
                },
                {
                    id: 7,
                    name: "Shazam",
                    iconImage: require('../../../assets/images/social/iconmonstr-shazam-5.png'),
                    url: "ttps://music.apple.com/us/album/time-fly/1622193791?i=1622193807",
                    albumName: {
                        timeFly: "timeFly"
                    },
                    advertising: false,
                    social: true,
                    tag: "shazam_listen"
                },
            ],
        }

    }
}