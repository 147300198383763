import React from "react";
import StreamingView from "../components/views/streamingViewComponent";
import release from '../releases/purpose/data/purposeRelease';

const PurposeStreaming = () => {
    return (
        <div>
            <StreamingView release={release} backgroundColor={'#23071a'}/>
        </div>
    );
};
export default PurposeStreaming;