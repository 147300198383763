import React from "react";
import StreamComponent from '../Streaming';
import Header from "../header/Header";


/* Documentation */
// https://dmitripavlutin.com/react-props/
// https://medium.com/@nutanbhogendrasharma/create-multiple-pages-using-routing-in-react-part-2-5d667ca051c6
// https://www.pluralsight.com/guides/change-page-background-color-each-route

const StreamingView = ({ release, backgroundColor }) => {
    return (
        <div>
            <div className="container-fluid">
                <div style={{backgroundColor: backgroundColor}} className="pageBg"/>
                
                {release.albumArt.map(art => (
                    <Header key={art.id} {...art}/>
                ))}

                {release.music.links.streaming.map(music => (
                    <StreamComponent key={music.id} {...music} />
                ))}
            </div>
        </div>
    );
};

export default StreamingView;
