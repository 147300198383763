module.exports = {
    icons: [
        // { 
        //     id: 6,
        //     name: "Facebook",
        //     iconImage: require('../images/social/iconmonstr-facebook-5.png'),
        //     url: "https://facebook.com/khriiistosarcana",
        //     tag: "facebook"
        // },
        {
            id: 1,
            name: "Instagram",
            iconImage: require("../images/social/iconmonstr-instagram-12.png"),
            url: "https://www.instagram.com/khriiistosarcana",
            tag: "instagram_follow"
        },
        {
            id: 2,
            name: "YouTube",
            iconImage: require('../images/social/youtube_icon.webp'),
            url: "https://www.youtube.com/khriiistosarcana?sub_confirmation=1",
            tag: "yt_subscribe"
        },
        {
            id: 3,
            name: "Shop",
            iconImage: require('../images/social/merch_shop.png'),
            url: "https://khriiistos-arcana.myshopify.com",
            tag: "shop"
        }
    ]
}