import React from "react";
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons'
import { analytics } from "../../utils/analyticsWrapper";
import { isIOS } from "react-device-detect";

const smsUrl = isIOS
  ? "sms:+14045000146&body=Music"
  : "sms:+14045000146?body=Music";

// let smsLink = require('sms-link')
// const smsUrl = smsLink({phone: '+14045000146', body: 'Music'})

const element = <FontAwesomeIcon icon={faMobileAlt} />

class Message extends React.Component {
  handleClick(name, payload) {
    console.log("clickEvent" + name);
    analytics.event(name, payload);
  }
  render() {
    return (
      <div className="sms-message-icon">
        <a href={smsUrl}>
          <Button
            variant="primary"
            size="lg"
            onClick={() => this.handleClick(this.props.tag, "")}
          > { element } Text <b>'Music'</b> for updates!
          </Button>
        </a>
      </div>
    );
  }
}

export default Message;
